import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection, ArticleHeader, ArticleDate, ArticleH1 } from "../../styledComponents/article"
import { FadingBackground } from "../../components/modalFadingBackground"
import {
  Section,
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Modal from "../../components/modal"  
import { Link } from "gatsby"

const WebsiteSpeed = ({ data, location, createCookie }) => {
    return (
      <Layout location={location}>
        <Seo 
          title="Boost Your Website Performance and Speed with Our Optimisation Services" 
          description="Improve your website's loading speed and overall performance with our professional optimisation services. Our experts use the latest techniques to enhance your website's functionality, ensuring a seamless user experience. Contact us now to learn more!"
          image=""
          url="/services/website-speed-performance"
        />
          <HeroTextImageWrapper backgroundColor="var(--light-blue-mid)" gridRowGap="0">
            <StaticImage
              loading="eager"
              // // layout="fullWidth"
              // // You can optionally force an aspect ratio for the generated image
              // aspectRatio={1 / 1}
              // // This is a presentational image, so the alt should be an empty string
              alt=""
              // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
              src={
                "../../images/site-speed.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
              <HeroText fontColor="var(--white)">
                  <HeroHeader level={1}>
                  Website Speed & Performance
                  </HeroHeader>
                  <HeroDescription>Page speed plays a critical role in determining your website's search engine optimization, user experience and conversion rates. Don't miss out on the opportunity to optimize your website's performance.</HeroDescription>
              </HeroText>
            </HeroTextImageWrapper>

            <Article
              itemScope
              itemType="http://schema.org/Article"
            >
              <ArticleHeader>
                <ArticleH1 itemProp="headline">Why Your Website Site Speed Is So Important</ArticleH1>
                <ArticleDate offScreen pubdate dateTime=""><span className="publishedon">Published on</span> 28th October 2020</ArticleDate>
              </ArticleHeader>
              <ArticleSection>

              <p>In 2023, your website's page speed has never been more important. </p>
              <p>It affects everything, from how your website ranks to your conversion rate, so having fast loading webpages is absolutely essential.</p>
              <p>In this guide we'll tell you everything you need to know, including:</p>

              <ul>
                <li><Link to={location.pathname + "#what-is-page-speed"}>What is page speed?</Link></li>
                <li><Link to={location.pathname + "#why-is-page-speed-important"}>Why is page speed important?</Link></li>
                <li><Link to={location.pathname + "#what-is-a-good-page-speed"}>What is a good page speed?</Link></li>
                <li><Link to={location.pathname + "#why-is-my-website-slow"}>Why is my website slow?</Link></li>
                <li><Link to={location.pathname + "#how-can-i-check-my-page-speed"}>How can I check my page speed?</Link></li>
                <li><Link to={location.pathname + "#how-can-i-optimise-my-page-speed"}>How can I optimise my page speed?</Link></li>
                <li><Link to={location.pathname + "#how-do-i-measure-my-page-loading-speed"}>How do I measure my page loading speed?</Link></li>
              </ul>
              <h2 id="what-is-page-speed">What is page speed?</h2>
              <p>Page load speed is the time it takes for a page on your website to load. But it's a bit of a catch-all term that really covers the speed of your website in general.</p>
              <p>Page speed is actually calculated in a few different ways, so here's a breakdown of the different elements that make up a page speed measurement.</p>

              <h3>Time to first byte (TTFB)</h3>
              <p>This is the length of time to load the very first byte of information on your page. It's usually a good indication of how fast the server loading time is.</p>

              <h3>Time to interactive (TTI)</h3>
              <p>TTI looks at the length of time it takes for a page to load the interactive elements. This is a good way to see if your website is being slowed down by javascript.</p>

              <h3>First contentful paint (FCP)</h3>
              <p>This fairly odd phrase actually refers to the time it takes for the first element of content to load on your website. That could be text, an image (even a background image) or svg.</p>

              <h3>Load time</h3>
              <p>The load time is the amount of time it takes to fully load a page and all its elements.</p>

              <h3>Mobile first</h3>
              <p>Technically this isn't a measurement of page speed, but it is another very important part of how your website's loading time is calculated - mobile first. That means that when assessing the speed of your website, the tool is actually looking at the mobile version of the site, not the desktop version.</p>

              <p>That's because speed is even more significant when you're using a mobile device and it's often here where the basics of page speed optimisation are overlooked.</p>

              <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>The first five seconds of page-load time have the highest impact on conversion rates. <span><a href="https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm#:~:text=The%20first%205%20seconds%20of,(between%20seconds%200%2D5)" target="_blank" rel="noreferrer">Portent, 2019</a></span></h2>

            </Section> 
              
              <h2 id="why-is-page-speed-important">Why is page speed important?</h2>
              <p>Page speed is probably the purest of SEO factors, because addressing loading times improves your rankings, your user experience and your conversion rate too.</p>

              <p>It's a rare example of an SEO measure that is a win for absolutely everyone!</p>

              <h3>Page speed and SEO</h3>
              <p>When it comes to SEO, page and site speed has become an essential part of how search engines rate your pages.</p>

              <p>That's because Google doesn't want to deliver results that are slow loading and put users off. They're trying to present the most relevant, most appropriate websites with their results.</p>

              <p>It's set to become an even more vital factor in May 2021 with the Google Page Experience Update.</p>

              <h3>Page speed and UX</h3>
              <p>User experience is really the big reason that search engines think website speed is such an important factor and that's why it's part of their ranking algorithms. </p>

              <p>It's a universally acknowledged fact that on the internet, no-one has an attention span of more than a couple of seconds. So if your webpage is taking more than 3 seconds to load, chances are the majority of your audience can't be bothered to wait around for it.</p>

              <p>You have to really want that content in order to wait that long.</p>

              <p>Faster websites create a better user experience and so, in turn, search engines want to promote those websites above the slow ones with higher bounce rates.</p>

              <h3>Page speed and CRO</h3>
              <p>The last of the three big benefits of speeding up your website is conversion rate optimisation. </p>

              <p>CRO often gets a big boost when you improve page speed because you're not losing users who are waiting for their page to load.</p>

              <p>So overall, improving your page speed is going to help you rank higher, give your users a better experience and improve your chances of getting that all-important conversion.</p>

              <Section background="var(--mid-blue-mid)" type="pullout">
                <h2>The first five seconds of page-load time have the highest impact on conversion rates. <span><a href="https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm#:~:text=The%20first%205%20seconds%20of,(between%20seconds%200%2D5)" target="_blank" rel="noreferrer">Portent, 2019</a></span></h2>
              </Section> 
              <h2 id="what-is-a-good-page-speed">What is a good page speed?</h2>
              <p>Most people would say that 2 seconds or less is a good loading time. But actually, this is a very difficult question to answer, as it does depend a lot on the type of page you have and how you're measuring your page speed.</p>

              <p>Remember that people don't necessarily need to wait for your entire page to fully load in order to use your website and get where they want to go. In some cases it's the time to interactive (TTI), or the time it takes to load the first bits of content (FCP) that's really important.</p>

            <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>The average mobile web page takes 15.3 seconds to load.  <span><a href="https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/mobile-shopping-ecosystem-page-load-speed/" target="_blank" rel="noreferrer">Google Research, 2018</a></span></h2>
            </Section> 

            <h2 id="why-is-my-website-slow">Why is my website slow?</h2>
            <p>There are a lot of reasons that your website might be running slowly, but here are a few of the most common issues and how to improve them.</p>

            <h3>Website speed and code overhead</h3>
            <p>Code overhead or bloat is a common factor in slowing down your website - particularly if you're using a platform like WordPress.</p>

            <p>When you use an off-the-shelf CMS, it has to carry a lot of code that's not really relevant to you or your website and that code can slow your load times considerably.</p>

            <h3>Page speed and assets</h3>
            <p>The assets on your website, like images and video, are also a really common factor in the speed of your webpages. </p>

            <p>When you're adding images to your website, lots of people use the highest resolution version they have - as that's going to be the best quality, right?</p>

            <p>Well, in a way that's true, but while you want to START with the highest quality of image, you also want to minimise the file size so it loads nice and quickly.</p>

            <h3>How your server affects page speed</h3>
            <p>One very common issue with loading speeds is having a slow server. And a big indicator that your hosting service isn't up to scratch is if your time to first byte is poor.</p>

            <p>If your host can't deliver your files quickly, then it doesn't matter how streamlined those files are, it's going to feel slow to your user.</p>

            <p>People often don't consider their hosting a worthwhile investment and simply go with the cheapest available - but they're often making a compromise with the speed and security of their website.</p>

            <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>Of all the people surveyed, half said they’d be willing to give up animation and video for faster load times. <span><a href="https://unbounce.com/page-speed-report/" target="_blank" rel="noreferrer">Unbounce, 2019</a></span></h2>

            </Section> 


            <h2 id="how-can-i-check-my-page-speed">How to check your page speed</h2>
            <p>There are lots of different tools available for testing your website loading times, but one of the most effective (and free!) services is Google PageSpeed Insights.</p>

            <p>Google's analysis of your website's speed is going to play a role in how your website ranks, so it makes sense to use their own tool to see how your website performs.</p>

            <p>Not only will PageSpeed Insights give you scores for your loading speeds, it also outlines what elements of your website are slowing you down and how to resolve those issues.</p>

            <p>For further insight, try combining it with the Experte Bulk Page Speed Test. This free bulk page speed analyser provides data on how each individual page of your website is performing, including TTFB, FCP and more.</p>


            <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>Website conversion rates drop by an average of 4.42% with each additional second of load time (between seconds 0-5). <span><a href="https://unbounce.com/page-speed-report/" target="_blank" rel="noreferrer">Portent, 2019</a></span></h2>
            </Section> 



            <h2 id="how-can-i-optimise-my-page-speed">How can I optimise my website page speed</h2>
            <p>There are lots of different ways you can improve your website's loading speed. These page speed optimisation methods are usually the best place to start as they address some of the biggest and most common issues that slow your webpages down.</p>

            <h3>Get a good host</h3>
            <p>As we've already discussed, even if your website is built and optimised to be super fast, if it's on a slow server you're not going to reap the benefits. So, choosing the best web hosting company for your needs is crucial.</p>

            <p>You may also want to consider where your hosting provider's servers are. Storing your files on servers located in the US when most of your customers are in the UK means that their request has to travel several thousand miles before reaching your website files.</p>

            <p>Of course, the request is moving incredibly quickly, but the further it has to go, the longer it takes. So it's usually best to have your servers in the same country as your audience.</p>

            <h3>Reduce image and file sizes</h3>
            <p>Images are often the biggest offenders when it comes to page speed. One simple change is to make sure you're using responsive images. That means your website isn't wasting time loading a desktop-sized image for your mobile website. </p>

            <p>Reducing the size of files on your website is also really important. There are lots of great image compression tools out there, such as Tinify, which will reduce the size of your files and images without losing quality. </p>

            <h3>Convert images to WebP</h3>
            <p>Another way to speed up your image loading speeds is to use WebP format instead of jpg, png or gif. This format is much faster loading without losing any image quality. Bear in mind that some legacy browsers don't support WebP, so you might have to use a more traditional format as a fallback.</p>

            <h3>Caching</h3>
            <p>Caching is when a browser saves a version of your webpage, so that when you go back to it you don't have to reload the entire thing all over again.</p>

            <p>This can hugely speed up your website and is pretty straightforward to implement. If your website is on WordPress, there's even a WordPress plugin that does it all for you.</p>

            <h3>Minified code</h3>
            <p>Code bloat or code overhead is often a factor in the speed of your website, so making sure you've minified your code is essential if you're going to have a good page speed.</p>

            <p>Of course, this can be much more difficult if you're relying on an off the shelf platform or software, so if you really want to keep your code streamlined, you might want to consider getting a bespoke website to start with.</p>

            <h3>Simplify redirects</h3>
            <p>This is a more common problem on websites that have been up and running for a while - especially if they've been through a couple of different iterations.</p>

            <p>When you change your website structure, you often need to put in redirects to ensure people visiting old URLs are still being directed to appropriate pages. </p>

            <p>But if you've restructured your website several times, it's common to have multiple redirects, loops or chains. All of these slow your website down as people get moved from URL to URL before reaching their ultimate landing page.</p>

            <p>Streamlining your redirects will remove unnecessary steps and make sure you're moving people to an appropriate page faster.</p>

            <h3>Content Delivery Network (CDN)</h3>
            <p>A content delivery network, or CDN stores a cached version of your website (or elements of your website) on servers in different locations. That means that instead of a request having to travel thousands of miles to access the files and load a page, they can be recovered quickly from a nearby server.</p>

            <p>CDNs are particularly useful if you have a large, complex website with lots of pages and files, or if you have a lot of traffic to your site.</p>


            <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>Today, even though most web traffic occurs on 4G instead of 3G, the majority of mobile sites are still slow and bloated due to too many page elements.<span><a href="https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/" target="_blank" rel="noreferrer">Google, 2018</a></span></h2>
            </Section> 
            {/* //////// */}

            <h2 id="how-do-i-measure-my-page-loading-speed">How do I measure my page loading speed?</h2>
            
            <p>You can use a website speed test tool such as Google PageSpeed Insights or GTmetrix, or use your web browser’s developer tools. In Google Chrome, for example, you can open the developer tools by pressing F12 or right-clicking on the page and selecting “Inspect,” then go to the “Network” tab and reload the page to see how long it takes to load.</p>

            <p>If you are using Google Analytics, you can also access the Site Speed reports under the Behavior tab, which are automatically tracked.</p>

            <p>Another Google tool that tracks your Core Web Vitals is Google Search Console, which measures real-world usage data of your website.</p>


            <h2>Why you should speed up your website</h2>
            <p>Page speed is a huge factor in your website's success, as it can impact your search engine rankings, your customer's satisfaction and your conversion rate. If your page is slow and sluggish, it could be a sign you need a new website.</p>

            <p>Whenever we provide SEO services, page speed is one of the first things we look at, as it's one of the easiest ways to make a big improvement on your website.</p>

            <p>So if you're concerned that your website's performance isn't up to standard, or want to see how to improve your page speed, get in touch with us and we'll be happy to help!</p>
          </ArticleSection>
          
        </Article>
        {/* <Section>
          <ContactSection background="var(--grey-lightest)">
            <h2>Get in touch</h2>
            <p>Got a question or need some help with your next web project? Our creative team is here to help and we'd love to hear from you.</p>
            <ContactFormV2 />
          </ContactSection>
        </Section> */}



            <HeroTextImageWrapper 
              backgroundColor="var(--orange-mid)" 
              // gridRowGap="0"
              // gridColum
            >
              <StaticImage
                layout="fullWidth"
                // layout="fullWidth"
                // layout="constrained"
                // // You can optionally force an aspect ratio for the generated image
                // aspectRatio={23 / 1}
                // // This is a presentational image, so the alt should be an empty string
                alt=""
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                src={
                  "../../images/speed-optimisation.jpg"
                }
                formats={["auto", "webp", "avif"]}
                className="padding1"
              />
              <HeroText fontColor="var(--white)">
                  <HeroHeader level={1}>Speed &amp; Performance</HeroHeader>
                  <HeroDescription>If your website doesn't load quickly, the bad news is that you're losing sales. People aren't willing to wait anymore. Even worse, slow websites often finish well below faster ones in search results. This means that customers see your competitors' sites before yours.</HeroDescription>
                  <ModalProvider backgroundComponent={FadingBackground}>
                    <Modal 
                      title="Speed & Performance audit" 
                      type="hero" 
                      serviceName="Speed & Performance"
                      buttonLabel="Request a Speed & Performance audit"
                      buttonStyle="HeroButton"
                      formExcerpt="Please fill in the following form and one of our experts will run a report and get back to you within 48 hours."
                    />
                  </ModalProvider>
              </HeroText>
            </HeroTextImageWrapper>

      </Layout>
    )
}

export default WebsiteSpeed
